import React, { useState } from "react"
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Snackbar,
  Typography,
  TextField,
  useMediaQuery,
  Divider
} from "@mui/material"
import { ShadowTheme } from '../admin/ShadowTemplate';
import { admin_users_two_factor_settings_path } from '../../routes';
import { hot } from "react-hot-loader";


const ConfirmationInputShadow = (props) => {
  return <ShadowTheme rootSelector={props.rootSelector}>
    <ConfirmationInput
      csrfToken={props.csrfToken}
      titleMessage={props.titleMessage}
      labelMessage={props.labelMessage}
      successMessage={props.successMessage}
    />
  </ShadowTheme>
}

const ConfirmationInput = ({ csrfToken, titleMessage, labelMessage, successMessage }) => {
  const [confirmation, setConfirmation] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const smallScreen = useMediaQuery((theme) => theme.breakpoints.up('sm'));

  const handleConfirmation = (event) => {
    setConfirmation(event.target.value);
  }

  const handleSubmit = async () => {
    try {
      if (confirmation !== "CONFIRMO") {
        setErrorMessage('É necessário digitar CONFIRMO para confirmar sua solicitação!');
        return;
      }

      const response = await fetch(admin_users_two_factor_settings_path(), {
        method: 'DELETE',
        headers: {
          "X-CSRF-Token": csrfToken,
          'Content-Type': 'application/json'
        }
      });

      if (response.ok) {
        setOpenSnackbar(true);
        setSnackbarMessage(successMessage)
      } else {
        const errorData = await response.json();
        setOpenSnackbar(true);
        setSnackbarMessage(`Ocorreu um erro ${errorData.error}`);
      }
    } catch (error) {
      setOpenSnackbar(true);
      setSnackbarMessage(`Ocorreu um erro ${error.message}`);
    }
  }

  return (
      <Card>
        <Typography variant="h6" sx={{ pt: 2, px: 2 }}>{titleMessage}</Typography>
        <Divider sx={{ width:'100%', mt: 1}} />
        <CardContent sx={{ p: 2 }}>
          <Typography>{labelMessage}</Typography>
          <TextField
            sx={{ my: 2.5, width: "100%" }}
            label="Digite CONFIRMO para confirmar a ação"
            variant="outlined"
            size="medium"
            value={confirmation}
            onChange={handleConfirmation}
            inputProps={{ "data-testid": "confirmation-input" }}
            data-testid={'confirmation-field'}
            error={!!errorMessage}
            helperText={errorMessage}
          />
        </CardContent>
        <CardActions sx={{ justifyContent: "end", p: 2 }}>
          <Button onClick={handleSubmit} variant="contained">
            Enviar
          </Button>
        </CardActions>

        <Snackbar
          data-testid="snackbar"
          open={openSnackbar}
          autoHideDuration={6000}
          message={snackbarMessage}
          anchorOrigin={{vertical: 'bottom', horizontal: 'center' }}
        />
      </Card>
  );
}

export default hot(module)(ConfirmationInputShadow);
